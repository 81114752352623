/* Webfont: Graphik-Bold */
@font-face {
  font-family: 'GraphikBold';
  src: url('../Fonts/Graphik-Bold.eot'); /* IE9 Compat Modes */
  src: url('../Fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../Fonts/Graphik-Bold.woff') format('woff'), /* Modern Browsers */
       url('../Fonts/Graphik-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../Fonts/Graphik-Bold.svg#Graphik-Bold') format('svg'); /* Legacy iOS */
  font-style: normal;
  font-weight: normal;
  text-rendering: optimizeLegibility;
}

/* Webfont: Helvetica */
@font-face {
  font-family: 'Helvetica';
  src: url('../Fonts/Helvetica-Light.woff2') format('woff2'),
      url('../Fonts/Helvetica-Light.woff') format('woff'),
      url('../Fonts/Helvetica-Light.svg#Helvetica-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../Fonts/Helvetica-Bold.woff2') format('woff2'),
      url('../Fonts/Helvetica-Bold.woff') format('woff'),
      url('../Fonts/Helvetica-Bold.svg#Helvetica-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../Fonts/Helvetica-Oblique.woff2') format('woff2'),
      url('../Fonts/Helvetica-Oblique.woff') format('woff'),
      url('../Fonts/Helvetica-Oblique.svg#Helvetica-Oblique') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../Fonts/Helvetica.woff2') format('woff2'),
      url('../Fonts/Helvetica.woff') format('woff'),
      url('../Fonts/Helvetica.svg#Helvetica') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Webfont: National */
@font-face {
  font-family: 'National-Condensed-Medium';
  src: url('../Fonts/National-Condensed-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Condensed-Regular';
  src: url('../Fonts/National-Condensed-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Semi-Bold';
  src: url('../Fonts/National-Medium.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'National-Regular';
  src: url('../Fonts/National-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

.beyondCustomerSupport-modal{
  width: 100% !important;
  max-width: 540px;
}

.beyondCustomerSupport-modal .ant-modal-content {
  max-width: 540px;
  width: 100%;
  background-color: #2B384B;
}
.beyondCustomerSupport-modal h4{
  font-family: 'GraphikBold',sans-serif;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;  
  color: #FFFFFF;
  margin: 0;
}
.beyondCustomerSupport-modal p{
  margin:  28px 0 0;
  font-family: 'Helvetica',sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #EAEAEA;
}
.beyondCustomerSupport-modal p strong{
  font-weight: bold;
}
.beyondCustomerSupport-modal h5{
  font-family: 'GraphikBold',sans-serif;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;  
  color: #FFFFFF;
  margin: 34px 0 18px;
}
.beyondCustomerSupport-modal ul{
  margin-bottom: 60px;
  padding-left: 1em;
}
.beyondCustomerSupport-modal ul li{
  margin-top: 24px;
  font-family: 'Helvetica',sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #EFEFEF;
}

.modal-divider{  
  background-color:  #4D4D4D;
  margin: 0 -30px;
  height: 1px;
}
.beyondCustomerSupport-modal .ant-modal-footer{
  display: none !important;
}
.beyondCustomerSupport-modal .modal-custom-footer{
  padding: 18px 0 10px;
}
.beyondCustomerSupport-modal .modal-custom-footer .seller-review{
  font-family: 'GraphikBold',sans-serif;
  font-weight: bold;
  font-size: 19px;
  line-height: 26px;
  color: #FFFFFF;
  max-width: 325px;
}
.beyondCustomerSupport-modal .modal-custom-footer .seller-name{
  display: block;
  margin-top: 8px;
  font-family: 'Helvetica',sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 25px;
  color: #ECF1F8;  
}
.beyondCustomerSupport-modal  .ant-modal-close {
  color: #fff !important;
}
.beyondCustomerSupport-modal  .ant-modal-close-x {
  font-size: 24px;
}

@media (max-width: 767px) {
  .beyondCustomerSupport-modal{
    max-width: calc(100% - 16px) 
  }
  .beyondCustomerSupport-modal .ant-modal-close-x {
    font-size: 16px;
  }
  .beyondCustomerSupport-modal .ant-modal-body {
    padding: 25px 15px 15px;
  }
  .beyondCustomerSupport-modal h4{
    font-size: 24px;
    line-height: 29px;  
  }
  .beyondCustomerSupport-modal h5{
    font-size: 19px;
    line-height: 24px;  
    margin: 48px 0 24px;
  }  
  .beyondCustomerSupport-modal ul{
    margin-bottom: 24px;
  }
  .modal-divider {
    margin: 0 -15px;
  }
}  
